import React from 'react';
import Iframe from 'react-iframe';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_au6pbqq',
        'template_ss1bfgp',
        e.target,
        'user_55rjM0lvUIHhHwk3kJHRI'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <>
      <section className='contact' id='kontak'>
        <div className='container'>
          <div className='text-center py-5'>
            <h2 className='py-3'>Kontak Kami</h2>
            <div className='mx-auto heading-line'></div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                id='map-container-google-1'
                className='z-depth-1-half map-container'
              >
                <Iframe
                  url='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15862.475660018124!2d106.7692527!3d-6.3136888!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x31fab31bdaf40c7d!2sAsia%20Trans%20Media!5e0!3m2!1sid!2sid!4v1642214395799!5m2!1sid!2sid'
                  width='100%'
                  height='420px'
                  id='myId'
                  className='myClassname'
                  display='initial'
                  position='relative'
                  allowfullscreen=''
                  aria-hidden='false'
                  tabindex='0'
                />
              </div>
            </div>
            <form className='col-lg-6' onSubmit={sendEmail}>
              <div className='form-group'>
                <label for='name'>Nama</label>
                <input
                  id='name'
                  className='form-control'
                  type='text'
                  aria-describedby='emailHint'
                  placeholder='Nama'
                  name='name'
                  required
                />
              </div>
              <div className='form-group'>
                <label for='email'>Email</label>
                <input
                  type='email'
                  id='email'
                  className='form-control'
                  placeholder='Email'
                  name='email'
                  required
                />
              </div>
              <div className='form-group'>
                <label for='message'>Pesan</label>
                <textarea
                  name='message'
                  id='message'
                  className='form-control'
                  rows='5'
                  placeholder='Pesan'
                  required
                ></textarea>
              </div>
              <button
                type='submit'
                name='Submit'
                className='btn hvr-right my-4 btn-lg'
              >
                Kirim
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
