import './Footer.css';

const Footer = () => {
  return (
    <>
      <footer>
        <div className='footer-content'>
          <h3>Penterjemah Tersumpah</h3>
          <p>
            Email : penterjemahbersumpah@gmail.com <br />
            Telp: +62 812 8999 0194
          </p>
        </div>
        <div className='footer-bottom'>
          <p>copyright &copy;2022 | Penterjemah Tersumpah</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
