import React, { useState } from 'react';
import Banner from './components/Banner/Banner';
import Hero from './components/Hero/Hero';
// import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import About from './components/About/About';
import Dokumen from './components/Dokumen/Dokumen';
import Lisan from './components/Lisan/Lisan';
import Legalisasi from './components/Legalisasi/Legalisasi';
import Whatsapp from './components/Whatsapp/Whatsapp';
import Contact from './components/Contact/Contact';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Router>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Hero />
        <About />
        <Dokumen />
        <Lisan />
        <Banner />
        <Legalisasi />
        <Contact />
        <Footer />
        <Whatsapp />
      </Router>
    </>
  );
}

export default App;
