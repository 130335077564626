import React from 'react';
import './Hero.css';
import { Link as LinkS } from 'react-scroll';

const Hero = () => {
  return (
    <>
      <div className='wrap2' id='hero'>
        <div className='content2'>
          <h1>ASIA TRANSLATION MEDIA BAHASA</h1>
          <p>Translation & Related Services</p>
          <div className='btn-group2'>
            <LinkS to='contact' className='color3'>
              Hubungi Kami
            </LinkS>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
